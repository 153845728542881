import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import Header from "./Home/components/Header";

import { FirebaseContext } from "../../Firebase";
import Footer from "./components/Footer";

import Home from "./Home";
import Cart from "./Cart";
import Checkout from "./Checkout";
import Order from "./Order";

import {
  useGetMenuList,
  useGetCategories,
  useGetCart,
  useUpdateCartWithItem,
  useGetShopSettingsData,
  useGetOrderList
} from "./hooks";

const useStyles = makeStyles(theme => ({
  mainContainer: { minHeight: "100vh" },

  mainGrid: {
    marginTop: theme.spacing(3)
  }
}));

export default function ClientApp() {
  const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);
  const { shopSettingsData } = useGetShopSettingsData(firebase.db);
  const deliveryAreaData = shopSettingsData
    ? shopSettingsData.deliveryAreas[0]
    : null;
  const [minPrice, setMinPrice] = React.useState(0);
  const { menuList } = useGetMenuList(firebase.db);
  const { categoryList } = useGetCategories(firebase.db);
  const { cartData } = useGetCart(firebase.db, firebase.auth.currentUser);
  const {
    updateCartWithItem,
    submitting,
    success,
    error
  } = useUpdateCartWithItem(firebase.functions);

  const { orderList } = useGetOrderList(
    firebase.db,
    firebase.auth.currentUser ? firebase.auth.currentUser.uid : null
  );

  const location = useLocation();

  const [canCheckout, setCanCheckout] = React.useState(false);
  React.useEffect(() => {
    if (cartData && deliveryAreaData && cartData.items) {
      const cartTotalPrice = Object.values(cartData.items).reduce(
        (prev, cur) => prev + cur.totalPrice,
        0
      );

      setCanCheckout(cartTotalPrice > deliveryAreaData.minPrice);
      setMinPrice(deliveryAreaData.minPrice);
    } else {
      setCanCheckout(false);
    }
  }, [cartData, deliveryAreaData]);

  const [visibleMenuList, setVisibleMenuList] = React.useState([]);
  const [currentCategoryID, setCurrentCategoryID] = React.useState(null);
  React.useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const category = params.get("category");

    if (!category || category === "p0qqhfVzAmMP2f1atqR6") {
      setVisibleMenuList(menuList);
      setCurrentCategoryID("p0qqhfVzAmMP2f1atqR6");
    } else {
      setVisibleMenuList(menuList.filter(item => item.category === category));
      setCurrentCategoryID(category);
    }
  }, [location.search, menuList]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.mainContainer}>
        <Header
          title="Магазин-Кафе СМЕНА"
          sections={categoryList}
          currentCategoryID={currentCategoryID}
          cartData={cartData}
        />
        <main>
          <Switch>
            <Route path="/cart">
              <Cart
                cartData={cartData}
                cartAction={{ updateCartWithItem, submitting, success, error }}
                canCheckout={canCheckout}
                minPrice={minPrice}
              />
            </Route>
            <Route path="/checkout">
              <Checkout
                deliveryAreaData={deliveryAreaData}
                cartID={cartData.id}
              />
            </Route>
            <Route path="/order">
              <Order orderList={orderList} />
            </Route>
            <Route path="/">
              <Home visibleMenuList={visibleMenuList} />
            </Route>
          </Switch>
        </main>
      </Container>
      <Footer
        title="Доставляйте еду со вкусом!"
        description="Разработка сервиса доставки для небольших ресторанов и кафе"
      />
    </React.Fragment>
  );
}
