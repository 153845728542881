import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

import CartContent from "./components/CartContent";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  map: {
    padding: "4px"
  }
}));

const Cart = props => {
  const classes = useStyles();
  const { cartData, cartAction, canCheckout, minPrice } = props;

  if (!cartData.items) {
    return (
      <div className={classes.root}>
        <Alert severity="info">Вы еще ничего не заказали. </Alert>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <CartContent
        cartItems={cartData.items ? Object.values(cartData.items) : []}
        cartAction={cartAction}
      />
      {cartData.items &&
        Object.values(cartData.items).length > 0 &&
        !canCheckout && (
          <Alert severity="info">
            Чтобы оформить доставку, необходимо заказать товар на сумму от ₽
            {minPrice}{" "}
          </Alert>
        )}
      {canCheckout && (
        <div className={classes.buttons}>
          <Button
            variant="outlined"
            to="/checkout"
            component={Link}
            size="large"
          >
            Заказать
          </Button>
        </div>
      )}
    </div>
  );
};
export default Cart;
