import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const Order = props => {
  const { orderList } = props;

  return (
    <Grid container justify="center" spacing={4}>
      {orderList.map(orderItem => (
        <Grid item xs={12}>
          <OrderCard key={orderItem.id} orderItem={orderItem} />
        </Grid>
      ))}
    </Grid>
  );
};
export default Order;

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginTop: "16px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  price: {
    display: "flex",
    justifyContent: "flex-end"
  }
});

const OrderCard = props => {
  const classes = useStyles();
  const { orderItem } = props;
  console.log("orderItem", orderItem);
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Ваш заказ
        </Typography>
        <Typography variant="h6" color="textSecondary">
          Статус{" "}
        </Typography>
        <Typography variant="h5">
          {" "}
          {statusLabelByStatus(orderItem.status)}
        </Typography>
        {orderItem && orderItem.items && (
          <List>
            {Object.entries(orderItem.items).map(([key, item]) => (
              <ListItem button>
                <ListItemText primary={`${item.count} x ${item.itemName}`} />
              </ListItem>
            ))}
          </List>
        )}
        <div className={classes.price}>
          <Typography color="textSecondary" variant="h5">
            ₽{orderItem.totalPrice}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

const statusLabelByStatus = status => {
  switch (status) {
    case "PENDING":
      return "В очереди на готовку";
    case "PREPARING":
      return "Готовится";
    case "DELIVERING":
      return "Доставка";
    case "CANCELED":
      return "Отменен";
    case "DONE":
      return "Выполнен";
    default:
      return "Статус неопределен";
  }
};
