import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3)
  },
  actionButton: {
    position: "absolute",
    right: "20px",
    bottom: "10px"
  },
  imageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "center"
  },
  uploadButton: { margin: "8px" },
  imageInput: {
    display: "none"
  },
  itemImage: {
    maxWidth: "200px"
  }
}));

const CategoryBar = ({
  categoryList,
  setActiveCategory,
  addCategory,
  submittingCategory,
  successSubmittingCategory,
  errorSubmittingCategory
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (data = null) => {
    setUpdateData(data);
    setOpen(true);
  };
  const handleClose = () => {
    console.log("closeeee");
    setUpdateData(null);
    setOpen(false);
  };

  const [updateData, setUpdateData] = React.useState(null);

  const handleTabChange = (obj, value) => {
    console.log("handleTabChange", value);
    setTabValue(value);
    setActiveCategory(value);
  };
  const [tabValue, setTabValue] = React.useState(
    categoryList.find((element, index, array) => element.name === "Все").id
  );

  return (
    <Grid container>
      <Paper square>
        {categoryList.length > 0 && (
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="disabled tabs example"
          >
            {categoryList.map(category => (
              <Tab label={category.name} value={category.id} />
            ))}
          </Tabs>
        )}
      </Paper>
      <IconButton color="primary" aria-label="upload picture" component="span">
        <AddIcon onClick={() => handleClickOpen()} />
      </IconButton>
      <CategoryForm
        handleClose={handleClose}
        open={open}
        submitCategoryData={value => addCategory(value)}
        submittingCategory={submittingCategory}
        successSubmittingCategory={successSubmittingCategory}
        errorSubmittingCategory={errorSubmittingCategory}
      />
    </Grid>
  );
};

export default CategoryBar;

const CategoryForm = ({
  handleClose,
  open,
  submitCategoryData,
  submittingCategory,
  error,
  successSubmittingCategory,
  updateData
}) => {
  const classes = useStyles();
  const [formData, setFormData] = React.useState({});

  React.useEffect(() => {
    console.log("updateData", updateData);
    setFormData(updateData ? updateData : {});
  }, [updateData]);

  const updateFormField = event => {
    const fieldName = event.target.name;
    const value = event.target.value;
    let newData = { ...formData };
    newData[fieldName] = value;
    setFormData(newData);
  };
  const submitData = async () => {
    await submitCategoryData(formData);
    if (successSubmittingCategory) {
      cleanFormAndClose();
    }
    if (error) {
      console.error("Form submit error: ", error);
    }
  };

  React.useEffect(() => {
    console.log(error, successSubmittingCategory);
    if (successSubmittingCategory) {
      cleanFormAndClose();
    }
  }, [error, successSubmittingCategory]);

  const cleanFormAndClose = () => {
    setFormData({});
    handleClose();
  };

  React.useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Категория</DialogTitle>
      <DialogContent>
        <DialogContentText>Название</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Название позиции"
          type="text"
          fullWidth
          name="name"
          value={formData.name ? formData.name : ""}
          onChange={updateFormField}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={submittingCategory}
          onClick={cleanFormAndClose}
          color="primary"
        >
          Отмена
        </Button>
        <Button
          disabled={submittingCategory}
          onClick={submitData}
          color="primary"
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
