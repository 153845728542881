import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { statusLabelByStatus } from "../../../utils";
import Title from "../Title";

import { FirebaseContext } from "../../../Firebase";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function ClientDetailPage() {
  const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);

  let { clientID } = useParams();
  const location = useLocation();
  //console.log(location.state.clientData);
  return (
    <React.Fragment>
      <Title>Detail</Title>
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={classes.paper}>
          <Title>{clientID}</Title>
          <Typography component="p" variant="h4">
            {location.state.name}
          </Typography>
          <Typography component="p" variant="h4">
            {location.state.phone}
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} lg={3}>
        <Paper className={classes.paper}>
          <Title>Подписки</Title>

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Старт</TableCell>
                <TableCell>Конец</TableCell>
                <TableCell>Осталось</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {location.state &&
                location.state.clientData &&
                location.state.clientData.subscriptionPurchases &&
                location.state.clientData.subscriptionPurchases.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.startTime}</TableCell>
                    <TableCell>{row.endTime}</TableCell>
                    <TableCell>{row.subscriptionLeftCount}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} lg={3}>
        <Paper className={classes.paper}>
          <Title>Заказы</Title>

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Сумма</TableCell>
                <TableCell>Статус</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {location.state &&
                location.state.clientData &&
                location.state.clientData.activeOrders &&
                location.state.clientData.activeOrders.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.totalAmount}</TableCell>
                    <TableCell>{statusLabelByStatus(row.status)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
