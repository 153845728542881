import React from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";

import Title from "../Title";
import { statusLabelByStatus } from "../../../utils";

import { FirebaseContext } from "../../../Firebase";
import { useOrderList, useUpdateOrderStatus } from "./hooks";
import OrderStatusForm from "./components/OrderStatusForm";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function Orders() {
  const firebase = React.useContext(FirebaseContext);

  const [open, setOpen] = React.useState(false);
  const [offerID, setOfferID] = React.useState(null);
  const [offerStatus, setOfferStatus] = React.useState(null);
  const handleClickOpen = (orderID) => {
    setOfferID(orderID);
    setOfferStatus(setOfferStatus);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { orderList } = useOrderList(firebase.db);
  const {
    updateOrderStatus,
    submitting,
    success,
    error,
  } = useUpdateOrderStatus(firebase.db);

  const submitStatus = (val) => {
    updateOrderStatus(offerID, val);
  };

  const orders = orderList.map((order) => (
    <Grid item xs={12} md={6}>
      <OrderCard
        orderDate={order.createdAtString}
        totalPrice={order.totalAmount}
        clientData={order.userData}
        address={order.deliveryData}
        items={order.cartData || []}
        status={order.status}
        id={order.id}
        handleClickOpen={handleClickOpen}
      />
    </Grid>
  ));
  return (
    <React.Fragment>
      <Title>Заказы</Title>
      <Grid container spacing={3}>
        {orders}
      </Grid>

      <OrderStatusForm
        handleClose={handleClose}
        open={open}
        submitStatus={submitStatus}
        submitting={submitting}
        success={success}
        error={error}
        currentStatusValue={offerStatus}
      />
    </React.Fragment>
  );
}

const OrderCard = ({
  orderDate,
  totalPrice,
  clientData,
  address,
  items,
  status,
  id,
  handleClickOpen,
}) => {
  const classes = {
    root: {
      minWidth: 275,
    },

    title: {
      fontSize: 14,
    },
    orderItem: { fontSize: 16 },
    pos: {
      marginBottom: 12,
    },
  };
  const orderItemList = items.map((orderItem) => (
    <li>
      <Typography
        className={classes.orderItem}
        color="textPrimary"
        gutterBottom
      >
        {orderItem.name} x {orderItem.quantity} шт.
      </Typography>
    </li>
  ));

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {orderDate}
        </Typography>
        <Typography variant="h5" component="h2">
          {totalPrice} ₽{" "}
          <StyledChip
            status={status}
            label={statusLabelByStatus(status)}
            onClickHandler={handleClickOpen}
            id={id}
          />
        </Typography>
        <ul>{orderItemList}</ul>
        {clientData && (
          <Typography variant="body2" component="p">
            {clientData.clientName};
            <a href={`tel:${clientData.clientPhone}`}>Позвонить</a>
          </Typography>
        )}
        <Typography variant="body2" component="p">
          {address}
        </Typography>
      </CardContent>
    </Card>
  );
};

const StyledChip = ({ label, status, onClickHandler, id }) => {
  console.log("LABEL", label, status);
  let color = "#d50000";
  if (status === "PREPARING") {
    color = "#2e7d32";
  }
  if (status === "DELIVERING") {
    color = "#0d47a1";
  }
  return (
    <Chip
      label={label}
      onClick={() => onClickHandler(id)}
      style={{ backgroundColor: color, color: "white" }}
    />
  );
};
