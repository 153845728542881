import React from "react";
import { Link as RouterLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
//import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import DialpadIcon from "@material-ui/icons/Dialpad";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SettingsIcon from "@material-ui/icons/Settings";
import ListIcon from "@material-ui/icons/List";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PeopleIcon from "@material-ui/icons/People";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

export const mainListItems = (
  <div>
    <ListItem button component={RouterLink} to="/order">
      <ListItemIcon>
        <ReceiptIcon />
      </ListItemIcon>
      <ListItemText primary="Заказы" />
    </ListItem>
    <ListItem button component={RouterLink} to="/currentsubscriptions">
      <ListItemIcon>
        <CardMembershipIcon />
      </ListItemIcon>
      <ListItemText primary="Текущие" />
    </ListItem>
    <ListItem button component={RouterLink} to="/codes">
      <ListItemIcon>
        <DialpadIcon />
      </ListItemIcon>
      <ListItemText primary="Коды" />
    </ListItem>
    <ListItem button component={RouterLink} to="/clients">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Клиенты" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Админ</ListSubheader>

    <ListItem button component={RouterLink} to="/subscriptions">
      <ListItemIcon>
        <AccountBalanceWalletIcon />
      </ListItemIcon>
      <ListItemText primary="Подписки" />
    </ListItem>
    <ListItem button component={RouterLink} to="/admins">
      <ListItemIcon>
        <SupervisedUserCircleIcon />
      </ListItemIcon>
      <ListItemText primary="Администраторы" />
    </ListItem>
  </div>
);

{
  /* <ListItem button component={RouterLink} to="/settings">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Настройки" />
    </ListItem> */
}
