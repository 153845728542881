import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AdminApp from "./Pages/AdminApp";
import ClientApp from "./Pages/ClientApp";
import { FirebaseContext } from "./Firebase";
import PublicRoute from "./components/PublicRoute";
import LoginPage from "./Pages/LoginPage";
import LoginVerifyEmailLinkPage from "./Pages/LoginVerifyEmailLinkPage";

function App() {
  const firebase = React.useContext(FirebaseContext);
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    if (firebase && firebase.auth) {
      return firebase.auth.onAuthStateChanged(function (user) {
        if (user) {
          user.getIdTokenResult().then((idTokenResult) => {
            if (idTokenResult.claims.isAdmin === true) {
              setUser(firebase.auth.currentUser);
            } else {
              setUser(null);
            }
          });
        } else {
          setUser(null);
        }
      });
    }
  }, [firebase]);

  React.useEffect(() => {
    console.log(user);
  }, [user]);

  return (
    <Router>
      <Switch>
        <PublicRoute path="/login/verify" state={{ user: user }}>
          <LoginVerifyEmailLinkPage />
        </PublicRoute>
        <PublicRoute path="/login" state={{ user: user }}>
          <LoginPage />
        </PublicRoute>
        <Route path="/">
          <AdminApp user={user} />
        </Route>
      </Switch>
    </Router>
  );
}

// {
/* <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders />
              </Paper>
            </Grid> */
// }

export default App;

// const useStyles = makeStyles(theme => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center"
//   },
//   messenger: {
//     height: "100%",
//     width: "100%",
//     alignItems: "stretch",
//     display: "flex",
//     justifContent: "flex-start",
//     flexDirection: "row"
//   },
//   chatList: {
//     width: "100%",
//     maxWidth: "300px",
//     height: "100%"
//   },
//   messageList: {
//     width: "100%",
//     display: "flex",
//     flexDirection: "column",
//     marginLeft: "0px",
//     flexGrow: "1"
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main
//   },
//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(1)
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2)
//   }
// }));
