import { useEffect, useState } from "react";

const Subscriptions = "Subscriptions";

const useGetVerificationCodes = (db) => {
  const [codes, setCodes] = useState([]);

  useEffect(() => {
    if (db) {
      db.collectionGroup("verificationCodes")
        .where("isActive", "==", true)
        .onSnapshot((querySnapshot) => {
          const dataList = [];
          querySnapshot.forEach(function (doc) {
            const docData = doc.data();
            docData.price = docData.price / 100;
            dataList.push({ id: doc.id, ...docData });
          });
          setCodes(dataList);
          console.log("subscriptions", codes);
        });
    }
  }, [db]);
  return { codes };
};

export { useGetVerificationCodes };

const useExhaustVerificationCode = (firebase) => {
  const [submitting, setSubmitting] = useState(false);
  const [successSubmitting, setSuccessSubmitting] = useState(false);
  const [errorSubmitting, setErrorSubmitting] = useState(null);

  const invalidateVerificationCode = async ({ codeID, subscriptionID }) => {
    setSubmitting(true);
    setSuccessSubmitting(false);
    setErrorSubmitting(false);

    console.log("invalidateVerificationCode", codeID, subscriptionID);
    const invalidateVerificationCodeWithStatus = firebase.functions.httpsCallable(
      "invalidateVerificationCodeWithStatus"
    );
    invalidateVerificationCodeWithStatus({
      codeID: codeID,
      status: "SUCCESS",
      subscriptionID: subscriptionID,
    })
      .then(function (result) {
        setSubmitting(false);
        setSuccessSubmitting(true);
        setErrorSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        setSuccessSubmitting(false);
        setErrorSubmitting(true);
      });
  };
  return {
    invalidateVerificationCode,
    submitting,
    successSubmitting,
    errorSubmitting,
  };
};
export { useExhaustVerificationCode };
