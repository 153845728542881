import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Link, useRouteMatch } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import Title from "../Title";

import { FirebaseContext } from "../../../Firebase";
import { useGetClientProfileDataList } from "./hooks";

// const useStyles = makeStyles((theme) => ({
//   seeMore: {
//     marginTop: theme.spacing(3),
//   },
//   actionButton: {
//     position: "absolute",
//     right: "20px",
//     bottom: "10px",
//   },
//   imageBlock: {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "start",
//     alignContent: "start",
//     alignItems: "center",
//   },
//   uploadButton: { margin: "8px" },
//   imageInput: {
//     display: "none",
//   },
//   itemImage: {
//     maxWidth: "200px",
//   },
// }));

export default function ClientListPage() {
  // const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);

  const { dataList: clientDataList } = useGetClientProfileDataList(firebase.db);
  let { path, url } = useRouteMatch();
  return (
    <React.Fragment>
      <Title>Клиенты</Title>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Имя</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientDataList.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.name ? row.name : "----"}</TableCell>
              <TableCell>
                <Link
                  to={{
                    pathname: `${url}/${row.id}`,
                    state: { clientData: row },
                  }}
                >
                  {row.id}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
