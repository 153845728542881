import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles(theme => ({
  cartItem: { marginTop: "8px" },
  root: {
    display: "flex"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    width: 151
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  }
}));

const CartItem = ({ item, updateCartWithItem }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [currentVal, setCurrentVal] = React.useState(1);

  return (
    <div className={classes.cartItem}>
      <Card className={classes.root}>
        <CardMedia
          className={classes.cover}
          image={item.itemImageURL}
          title="Live from space album cover"
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {item.itemName}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {item.totalPrice}
            </Typography>
          </CardContent>
          <div className={classes.controls}>
            <IconButton
              aria-label="previous"
              onClick={() => updateCartWithItem(item.itemID, -1)}
            >
              {theme.direction === "rtl" ? <AddIcon /> : <RemoveIcon />}
            </IconButton>
            <Typography variant="h5" color="textSecondary">
              {item.count}
            </Typography>
            <IconButton
              aria-label="next"
              onClick={() => updateCartWithItem(item.itemID)}
            >
              {theme.direction === "rtl" ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default CartItem;
