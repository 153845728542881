import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  imageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "center"
  },
  uploadButton: { margin: "8px" },
  imageInput: {
    display: "none"
  },
  itemImage: {
    maxWidth: "200px"
  }
}));

const DeliveryAddressForm = ({
  handleClose,
  open,
  submitObjectData,
  submitting,
  error,
  success,
  updateData,
  dataList
}) => {
  const classes = useStyles();
  const [formData, setFormData] = React.useState({});

  React.useEffect(() => {
    console.log("updateData", updateData);
    setFormData(updateData ? updateData : {});
  }, [updateData]);

  const updateFormField = event => {
    const fieldName = event.target.name;
    const value =
      fieldName === "isDigital" ? event.target.checked : event.target.value;
    let newData = { ...formData };
    newData[fieldName] = value;
    setFormData(newData);
  };
  const submitData = async () => {
    await submitObjectData(formData);
    if (success) {
      cleanFormAndClose();
    }
    if (error) {
      console.error("Form submit error: ", error);
    }
  };

  React.useEffect(() => {
    console.log(error, success);
    if (success) {
      cleanFormAndClose();
    }
  }, [error, success]);

  const cleanFormAndClose = () => {
    setFormData({});
    handleClose();
  };

  React.useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Вариант доставки</DialogTitle>
      <DialogContent>
        <DialogContentText>Название</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Название позиции"
          type="text"
          fullWidth
          name="name"
          value={formData.name ? formData.name : ""}
          onChange={updateFormField}
        />
      </DialogContent>
      <DialogContent>
        <DialogContentText>Мин стоимость</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="minPrice"
          label="Минимальная стоимость заказа"
          type="number"
          fullWidth
          name="minPrice"
          value={formData.minPrice ? formData.minPrice : ""}
          onChange={updateFormField}
        />
      </DialogContent>
      <DialogContent>
        <DialogContentText>Координаты зоны доставки</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="area"
          label="[[59.93, 30.36],[59.94, 30.36],[59.93, 30.36]]"
          type="text"
          fullWidth
          name="area"
          value={formData.area ? formData.area : ""}
          onChange={updateFormField}
        />
      </DialogContent>
      <DialogContent>
        <DialogContentText>Цвет зоны доставки</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="fillColor"
          label="#001122"
          type="color"
          fullWidth
          name="fillColor"
          value={formData.fillColor ? formData.fillColor : "#6699ff"}
          onChange={updateFormField}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={submitting}
          onClick={cleanFormAndClose}
          color="primary"
        >
          Отмена
        </Button>
        <Button disabled={submitting} onClick={submitData} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryAddressForm;
