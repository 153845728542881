import { useEffect, useState } from "react";
import moment from "moment";

const ClientProfileCollection = "ClientProfiles";

const useGetClientProfileDataList = (db) => {
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (db) {
      return db
        .collection(ClientProfileCollection)
        .onSnapshot((querySnapshot) => {
          const dataList = [];
          querySnapshot.forEach(function (doc) {
            const docData = doc.data();
            console.log("___subscriptionStartDate", docData);

            const purchases = docData.activeClientSubscriptions;

            const updatedPurchases = purchases.map((purchase) => {
              const start = purchase.subscriptionStartDate
                ? moment.unix(purchase.subscriptionStartDate.seconds)
                : "";
              const end = purchase.subscriptionEndDate
                ? moment.unix(purchase.subscriptionEndDate.seconds)
                : "";
              const formatStr = "DD.MM.YYYY";
              // console.log("start end", start, end);
              const currDate = moment();
              const isActive = currDate.isBetween(start, end, "day", []);
              const dates = !!start
                ? {
                    startTime: `${start.format(formatStr)}`,
                    endTime: `${end.format(formatStr)}`,
                    isActiveTimeFrame: isActive,
                  }
                : {};
              return { ...dates, ...purchase };
            });

            //console.log("dates", dates);
            dataList.push({
              id: doc.id,
              ...docData,
              subscriptionPurchases: updatedPurchases,
            });
          });
          setDataList(dataList);
        });
    }
  }, [db]);
  return { dataList };
};

export { useGetClientProfileDataList };
