import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import NoImage from "../../../../images/noimage.png";

const useStyles = makeStyles(theme => ({
  imageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "center"
  },
  uploadButton: { margin: "8px" },
  imageInput: {
    display: "none"
  },
  itemImage: {
    maxWidth: "200px"
  }
}));

const MenuItemForm = ({
  handleClose,
  open,
  submitEmployeeData,
  submitting,
  error,
  success,
  updateData,
  menuList,
  categoryList,
  sendFiles,
  isSending
}) => {
  const classes = useStyles();
  const [formData, setFormData] = React.useState({});

  React.useEffect(() => {
    console.log("updateData", updateData);
    setFormData(updateData ? updateData : {});
  }, [updateData]);

  const updateFormField = event => {
    const fieldName = event.target.name;
    const value =
      fieldName === "isDigital" ? event.target.checked : event.target.value;
    let newData = { ...formData };
    newData[fieldName] = value;
    setFormData(newData);
  };
  const submitData = async () => {
    await submitEmployeeData(formData);
    if (success) {
      cleanFormAndClose();
    }
    if (error) {
      console.error("Form submit error: ", error);
    }
  };

  React.useEffect(() => {
    console.log(error, success);
    if (success) {
      cleanFormAndClose();
    }
  }, [error, success]);

  const cleanFormAndClose = () => {
    setFormData({});
    handleClose();
  };

  React.useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <Paper elevation={3} className={classes.imageBlock}>
        <Grid item>
          <img
            src={formData.url ? formData.url : NoImage}
            className={classes.itemImage}
          />
        </Grid>

        <input
          accept="image/*"
          className={classes.imageInput}
          id="contained-button-file"
          multiple
          type="file"
          onChange={e => {
            sendFiles(e.target.files, formData.id);
          }}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.uploadButton}
            disabled={isSending}
          >
            Upload
          </Button>
        </label>
      </Paper>
      <DialogTitle id="form-dialog-title">Позиция</DialogTitle>
      <DialogContent>
        <DialogContentText>Название</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="fullName"
          label="Название позиции"
          type="text"
          fullWidth
          name="fullName"
          value={formData.fullName ? formData.fullName : ""}
          onChange={updateFormField}
        />
      </DialogContent>
      <DialogContent>
        <DialogContentText>Цена</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="price"
          label="Цена позиции"
          type="number"
          fullWidth
          name="price"
          value={formData.price ? formData.price : ""}
          onChange={updateFormField}
        />
      </DialogContent>
      <DialogContent>
        <DialogContentText>Категория</DialogContentText>
        <Select
          name="category"
          id="category"
          value={formData.category ? formData.category : "p0qqhfVzAmMP2f1atqR6"}
          onChange={updateFormField}
        >
          {categoryList.map(cat => (
            <MenuItem value={cat.id}>{cat.name}</MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={submitting}
          onClick={cleanFormAndClose}
          color="primary"
        >
          Отмена
        </Button>
        <Button disabled={submitting} onClick={submitData} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MenuItemForm;
