import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { PENDING, PREPARING, DELIVERING, CANCELED, DONE } from "../const";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const OrderStatusForm = ({
  handleClose,
  open,
  submitStatus,
  submitting,
  error,
  success,
  currentStatusValue,
}) => {
  const classes = useStyles();
  const [statusValue, setStatusValue] = React.useState(currentStatusValue);

  const submitData = async () => {
    await submitStatus(statusValue);
    if (success) {
      cleanFormAndClose();
    }
    if (error) {
      console.error("Form submit error: ", error);
    }
  };

  React.useEffect(() => {
    console.log(error, success);
    if (success) {
      cleanFormAndClose();
    }
  }, [error, success]);

  const cleanFormAndClose = () => {
    //setFormData({});
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Изменить статус заказа</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="demo-dialog-native">Статус</InputLabel>
          <Select
            native
            value={statusValue}
            onChange={(e) => setStatusValue(e.target.value)}
            input={<Input id="demo-dialog-native" />}
          >
            <option value={PENDING}>Новый</option>
            <option value={PREPARING}>Готовится</option>
            <option value={DELIVERING}>Доставка</option>
            <option value={CANCELED}>Отмена</option>
            <option value={DONE}>Выполнен</option>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={submitting}
          onClick={cleanFormAndClose}
          color="primary"
        >
          Отмена
        </Button>
        <Button disabled={submitting} onClick={submitData} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderStatusForm;
