import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import UpdateIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import Title from "../Title";

import { FirebaseContext } from "../../../Firebase";
import { useGetSubscriptions, useUpdateSubscription } from "./hooks";

import SubscriptionForm from "./components/SubscriptionForm";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  actionButton: {
    position: "absolute",
    right: "20px",
    bottom: "10px",
  },
  imageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "center",
  },
  uploadButton: { margin: "8px" },
  imageInput: {
    display: "none",
  },
  itemImage: {
    maxWidth: "200px",
  },
}));

export default function Settings() {
  const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);
  const { subscriptions } = useGetSubscriptions(firebase.db);
  const {
    updateSubscription,
    submitting,
    successSubmitting,
    errorSubmitting,
  } = useUpdateSubscription(firebase.db);

  const [open, setOpen] = React.useState(false);
  const [updateData, setUpdateData] = React.useState(null);
  const handleClickOpen = (data = null) => {
    setUpdateData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setUpdateData(null);
    setOpen(false);
  };

  const updateItemData = (data) => {
    // const settingsData = [];
    // settingsData.push(data);
    console.log("subscriptionData", data);
    updateSubscription({ subscriptionData: data });
  };

  // const [deliverySubscriptionList, setSubscriptionList] = React.useState([]);

  // React.useEffect(() => {
  //   if (shopSettingsData && shopSettingsData.deliveryAreas) {
  //     setDeliveryAreaList(shopSettingsData.deliveryAreas);
  //   }
  // }, [shopSettingsData]);

  return (
    <React.Fragment>
      <Title>Подписки</Title>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Название</TableCell>
            <TableCell>Стоимость</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <IconButton
                  aria-label="update"
                  className={classes.margin}
                  onClick={() => handleClickOpen(row)}
                >
                  <UpdateIcon fontSize="small" />
                </IconButton>
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.price}₽</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.actionButton}
        onClick={() => handleClickOpen()}
      >
        <AddIcon />
      </Fab>
      <SubscriptionForm
        handleClose={handleClose}
        open={open}
        submitObjectData={updateItemData}
        submitting={submitting}
        success={successSubmitting}
        error={errorSubmitting}
        updateData={updateData}
        //deliveryAreaList={deliveryAreaList}
      />
    </React.Fragment>
  );
}
