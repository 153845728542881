import { useEffect, useState } from "react";

const useAddAdmin = (db) => {
  const [submitting, setSubmitting] = useState(false);
  const [successSubmitting, setSuccessSubmitting] = useState(false);
  const [errorSubmitting, setErrorSubmitting] = useState(null);

  const addAdmin = async ({ email }) => {
    setSubmitting(true);
    setSuccessSubmitting(false);
    setErrorSubmitting(false);

    try {
      await db.collection("Admins").add({ email });
      setSubmitting(false);
      setSuccessSubmitting(true);
      setErrorSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setSuccessSubmitting(false);
      setErrorSubmitting(error.message);
      console.log(error.message);
    }
  };

  return {
    addAdmin,
    submitting,
    successSubmitting,
    errorSubmitting,
  };
};

export { useAddAdmin };

const useDeleteAdmin = (db) => {
  const [submitting, setSubmitting] = useState(false);
  const [successSubmitting, setSuccessSubmitting] = useState(false);
  const [errorSubmitting, setErrorSubmitting] = useState(null);

  const deleteAdmin = async ({ ID }) => {
    setSubmitting(true);
    setSuccessSubmitting(false);
    setErrorSubmitting(false);

    try {
      await db.collection("Admins").doc(ID).delete();
      setSubmitting(false);
      setSuccessSubmitting(true);
      setErrorSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setSuccessSubmitting(false);
      setErrorSubmitting(error.message);
      console.log(error.message);
    }
  };

  return {
    deleteAdmin,
    submitting,
    successSubmitting,
    errorSubmitting,
  };
};

export { useDeleteAdmin };
