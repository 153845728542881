import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles((theme) => ({
  imageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "center",
  },
  uploadButton: { margin: "8px" },
  imageInput: {
    display: "none",
  },
  itemImage: {
    maxWidth: "200px",
  },
}));

const SubscriptionForm = ({
  handleClose,
  open,
  submitObjectData,
  submitting,
  error,
  success,
  updateData,
  //dataList,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = React.useState({});

  React.useEffect(() => {
    console.log("updateData", updateData);
    setFormData(updateData ? updateData : {});
  }, [updateData]);

  const updateFormField = (event) => {
    const fieldName = event.target.name;
    const value =
      fieldName === "isActive" ? event.target.checked : event.target.value;
    let newData = { ...formData };
    newData[fieldName] = value;
    setFormData(newData);
  };
  const submitData = async () => {
    await submitObjectData(formData);
    if (success) {
      cleanFormAndClose();
    }
    if (error) {
      console.error("Form submit error: ", error);
    }
  };

  React.useEffect(() => {
    console.log(error, success);
    if (success) {
      cleanFormAndClose();
    }
  }, [error, success]);

  const cleanFormAndClose = () => {
    setFormData({});
    handleClose();
  };

  React.useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Подписка</DialogTitle>
      <DialogContent>
        <DialogContentText>Название</DialogContentText>
        <TextField
          required
          autoFocus
          margin="dense"
          id="name"
          label="Название подписки"
          type="text"
          fullWidth
          name="name"
          value={formData.name ? formData.name : ""}
          onChange={updateFormField}
        />
      </DialogContent>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="demo-dialog-native">Период</InputLabel>
          <Select
            native
            name="period"
            value={formData.period ? formData.period : "WEEK"}
            onChange={updateFormField}
            input={<Input id="demo-dialog-native" />}
          >
            <option value="WEEK">Неделя</option>
            <option value="MONTH">Месяц</option>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="periodMultiplier">Мультипликтор</InputLabel>
          <Select
            native
            name="periodMultiplier"
            value={formData.periodMultiplier ? formData.periodMultiplier : null}
            onChange={updateFormField}
            input={<Input id="periodMultiplier" />}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="demo-dialog-native">Кол-во ланчей</InputLabel>
          <Select
            native
            name="useCount"
            value={formData.useCount ? formData.useCount : "1"}
            onChange={updateFormField}
            input={<Input id="demo-dialog-native" />}
          >
            <option value="1">1</option>
            <option value="2">3</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogContent>
        <DialogContentText>Описание</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Опишите услугу"
          type="text"
          fullWidth
          name="description"
          value={formData.description ? formData.description : ""}
          onChange={updateFormField}
        />
      </DialogContent>
      {false && (
        <>
          <DialogContent>
            <DialogContentText>Стоимость</DialogContentText>
            <TextField
              required
              autoFocus
              margin="dense"
              id="price"
              label="Стоимость подписки"
              type="number"
              fullWidth
              name="price"
              value={formData.price ? formData.price : ""}
              onChange={updateFormField}
            />
          </DialogContent>
        </>
      )}
      <DialogContent>
        <DialogContentText>Ext. SKU</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="extsku"
          label="Введите значение из Tilda"
          type="text"
          fullWidth
          name="extsku"
          value={formData.extsku ? formData.extsku : ""}
          onChange={updateFormField}
        />
      </DialogContent>
      <DialogContent>
        <DialogContentText>Включено</DialogContentText>
        <Checkbox
          id="isActive"
          name="isActive"
          checked={formData.isActive ? formData.isActive : false}
          onChange={updateFormField}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={submitting}
          onClick={cleanFormAndClose}
          color="primary"
        >
          Отмена
        </Button>
        <Button disabled={submitting} onClick={submitData} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionForm;
