import { useEffect, useState } from "react";
import moment from "moment";

const Orders = "Orders";
const useOrderList = (db) => {
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    if (db) {
      db.collection(Orders)
        .where("isActive", "==", true)
        .onSnapshot((querySnapshot) => {
          const dataList = [];
          querySnapshot.forEach(function (doc) {
            const docData = doc.data();

            const momentTS = moment.unix(docData.createdAt.seconds);
            const tsString = momentTS.format("DD.MM.YYYY, HH:mm");
            console.log("tsString", tsString);
            dataList.push({
              id: doc.id,
              ...{ createdAtString: tsString, ...docData },
            });
          });
          setOrderList(dataList);
        });
    }
  }, [db]);
  return { orderList };
};

export { useOrderList };

const useUpdateOrderStatus = (db) => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const updateOrderStatus = async (orderID, status) => {
    setSubmitting(true);
    setSuccess(false);
    setError(false);
    console.log("status Data", status);
    try {
      if (orderID) {
        if (status === "CANCELED" || status === "DONE") {
          await db
            .doc(`Orders/${orderID}`)
            .update({ status: status, isActive: false });
        } else {
          await db.doc(`Orders/${orderID}`).update({ status: status });
        }
      }
      setSubmitting(false);
      setSuccess(true);
      setError(false);
    } catch (error) {
      setSubmitting(false);
      setSuccess(false);
      setError(error.message);
    }
  };
  return { updateOrderStatus, submitting, success, error };
};

export { useUpdateOrderStatus };
