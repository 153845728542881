import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles((theme) => ({
  imageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "center",
  },
  uploadButton: { margin: "8px" },
  imageInput: {
    display: "none",
  },
  itemImage: {
    maxWidth: "200px",
  },
}));

const PurchaseForm = ({
  handleClose,
  open,
  submitObjectData,
  submitting,
  error,
  success,
  updateData,
  subscriptionDataList,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = React.useState({});

  const [canSubmit, setCanSubmit] = React.useState(false);
  React.useEffect(() => {
    if (subscriptionDataList && subscriptionDataList.length) {
      setFormData({ subscription: subscriptionDataList[0].id });
    }
  }, [subscriptionDataList]);

  React.useEffect(() => {
    console.log("updateData", updateData);
    setFormData(updateData ? updateData : formData);
  }, [updateData]);

  const updateFormField = (event) => {
    const fieldName = event.target.name;
    const value =
      fieldName === "isActive" ? event.target.checked : event.target.value;
    let newData = { ...formData };
    newData[fieldName] = value;
    setFormData(newData);
  };
  const submitData = async () => {
    await submitObjectData(formData);
    if (success) {
      cleanFormAndClose();
    }
    if (error) {
      console.error("Form submit error: ", error);
    }
  };

  React.useEffect(() => {
    console.log(error, success);
    if (success) {
      cleanFormAndClose();
    }
  }, [error, success]);

  const cleanFormAndClose = () => {
    setFormData({});
    handleClose();
  };

  React.useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Подписка</DialogTitle>
      <DialogContent>
        <DialogContentText>Email</DialogContentText>
        <TextField
          required
          autoFocus
          margin="dense"
          id="email"
          label="Email"
          type="email"
          fullWidth
          name="email"
          value={formData.email ? formData.email : ""}
          onChange={updateFormField}
        />
      </DialogContent>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="periodMultiplier">Подписка</InputLabel>
          <Select
            native
            name="subscription"
            value={formData.subscription ? formData.subscription : null}
            onChange={updateFormField}
            input={<Input id="subscription" />}
          >
            {subscriptionDataList &&
              subscriptionDataList.map((subscription) => (
                <option value={subscription.id}>{subscription.name}</option>
              ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={submitting}
          onClick={cleanFormAndClose}
          color="primary"
        >
          Отмена
        </Button>
        <Button disabled={submitting} onClick={submitData} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PurchaseForm;
