import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import Title from "../Title";

import { FirebaseContext } from "../../../Firebase";
import { useGetSubscriptionPurchases, useAddPurchase } from "./hooks";
import { useGetSubscriptions } from "../Subscriptions/hooks";
import PurchaseForm from "./components/PurchaseForm";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  actionButton: {
    position: "absolute",
    right: "20px",
    bottom: "10px",
  },
  imageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "center",
  },
  uploadButton: { margin: "8px" },
  imageInput: {
    display: "none",
  },
  itemImage: {
    maxWidth: "200px",
  },
}));

export default function ClientSubscriptions() {
  const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);
  const { subscriptionPurchases } = useGetSubscriptionPurchases(firebase.db);
  const { subscriptions } = useGetSubscriptions(firebase.db);

  // WRITE DB
  const {
    addPurchase,
    submitting,
    successSubmitting,
    errorSubmitting,
  } = useAddPurchase(firebase.functions);

  // FORM UI
  const [open, setOpen] = React.useState(false);
  const [updateData, setUpdateData] = React.useState(null);
  const handleClickOpen = (data = null) => {
    setUpdateData(data);
    setOpen(true);
  };
  const handleClose = () => {
    setUpdateData(null);
    setOpen(false);
  };

  // FORM SUBMIT
  const updateItemData = (data) => {
    // const settingsData = [];
    // settingsData.push(data);
    console.log("email", data);
    addPurchase(data);
  };
  return (
    <React.Fragment>
      <Title>Подписки</Title>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Название</TableCell>
            <TableCell>Клиент</TableCell>
            <TableCell>Осталось</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptionPurchases.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.subscriptionName}</TableCell>
              <TableCell>{row.userData && row.userData.clientName}</TableCell>
              <TableCell>{row.subscriptionLeftCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.actionButton}
        onClick={() => handleClickOpen()}
      >
        <AddIcon />
      </Fab>
      <PurchaseForm
        handleClose={handleClose}
        open={open}
        submitObjectData={updateItemData}
        submitting={submitting}
        success={successSubmitting}
        error={errorSubmitting}
        updateData={updateData}
        subscriptionDataList={subscriptions}
        //deliveryAreaList={deliveryAreaList}
      />
    </React.Fragment>
  );
}
