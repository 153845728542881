import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import UpdateIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";

import { FirebaseContext } from "../../../Firebase";
import Title from "../Title";
import { useAddAdmin, useDeleteAdmin } from "./hooks";
import AdminEmailForm from "./components/AdminEmailForm";

export default function AdminEmailList() {
  const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);

  // WRITE DB
  const {
    addAdmin,
    submitting,
    successSubmitting,
    errorSubmitting,
  } = useAddAdmin(firebase.db);
  const {
    deleteAdmin,
    submitting: deletionSubmitting,
    successSubmitting: deletionSuccessSubmitting,
    errorSubmitting: deletionErrorSubmitting,
  } = useDeleteAdmin(firebase.db);

  // READ DB
  const [adminEmails, setAdminEmails] = React.useState([]);

  React.useEffect(() => {
    firebase.db.collection("Admins").onSnapshot((qSnapshot) => {
      const data = [];
      qSnapshot.docs.forEach((docSnapshot) => {
        data.push({ id: docSnapshot.id, ...docSnapshot.data() });
      });
      setAdminEmails(data);
    });
  }, [firebase.db]);

  // FORM UI
  const [open, setOpen] = React.useState(false);
  const [updateData, setUpdateData] = React.useState(null);
  const handleClickOpen = (data = null) => {
    setUpdateData(data);
    setOpen(true);
  };
  const handleClose = () => {
    setUpdateData(null);
    setOpen(false);
  };

  const handleDeleteRow = (data) => {
    deleteAdmin({ ID: data.id });
  };

  // FORM SUBMIT
  const updateItemData = (data) => {
    // const settingsData = [];
    // settingsData.push(data);
    console.log("email", data);
    addAdmin(data);
  };

  return (
    <React.Fragment>
      <Title>Администраторы</Title>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adminEmails.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <IconButton
                  aria-label="update"
                  className={classes.margin}
                  onClick={() => handleDeleteRow(row)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
              <TableCell>{row.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.actionButton}
        onClick={() => handleClickOpen()}
      >
        <AddIcon />
      </Fab>
      <AdminEmailForm
        handleClose={handleClose}
        open={open}
        submitObjectData={updateItemData}
        submitting={submitting}
        success={successSubmitting}
        error={errorSubmitting}
        updateData={updateData}
        //deliveryAreaList={deliveryAreaList}
      />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  actionButton: {
    position: "absolute",
    right: "20px",
    bottom: "10px",
  },
  imageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "center",
  },
  uploadButton: { margin: "8px" },
  imageInput: {
    display: "none",
  },
  itemImage: {
    maxWidth: "200px",
  },
}));
