import React from "react";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import { FirebaseContext } from "../../../Firebase";
import DeliveryAddress from "./components/DeliveryAddress";
import PaymentForm from "./components/PaymentForm";

import {
  useCreateInvoice,
  useGetInvoice,
  useCreatePaymentIntent,
  useGetPayment
} from "./hooks";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  map: {
    padding: "4px"
  }
}));

function getSteps() {
  return ["Доставка", "Оплата"];
}

function getStepContent(step, deliveryAreaData, setDeliveryData) {
  switch (step) {
    case 0:
      return (
        <DeliveryAddress
          setDeliveryData={setDeliveryData}
          deliveryAreaData={deliveryAreaData}
        />
      );
    case 1:
      return <PaymentForm />;

    default:
      return "Unknown step";
  }
}

const Checkout = props => {
  const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);

  const { cartID, deliveryAreaData } = props;

  // LOCALLY GENERATED
  const [deliveryData, setDeliveryData] = React.useState({});

  // TO SERVER AND FROM IT [START]
  const { createInvoice, submitting, success, error } = useCreateInvoice(
    firebase.functions
  );
  const { invoiceData } = useGetInvoice(firebase.db, cartID);

  React.useEffect(() => {
    console.log("invoiceData", invoiceData);
  }, [invoiceData]);

  const {
    createPaymentIntent,
    submitting: paymentIntentSubmitting,
    success: paymentIntentSuccess,
    error: paymentIntentError
  } = useCreatePaymentIntent(firebase.functions);
  const { paymentData } = useGetPayment(firebase.db);
  // TO SERVER AND FROM IT [END]

  // WIZARD [START]
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    console.log("ACTIVE STEP", activeStep, "has invoiceData", !!invoiceData);
    if (activeStep === 0 && deliveryData.isValid) {
      createInvoice({ cartID: cartID, deliveryData: deliveryData });
    }
    if (activeStep === 1 && invoiceData) {
      console.log("CALL createPaymentIntent with incoiceData", invoiceData.id);
      createPaymentIntent(invoiceData.id);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  // WIZARD [END]

  // DELIVERY => NEXT
  React.useEffect(() => {
    if (success && activeStep === 0 && invoiceData) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  }, [success, invoiceData, activeStep]);

  // PAYMENT => FINISH
  React.useEffect(() => {
    if (paymentIntentSuccess && activeStep === 1 && paymentData) {
      console.log("PAYMENT DONE!!!");
    }
  }, [paymentIntentSuccess, paymentData, activeStep]);

  return (
    <div className={classes.root}>
      {!cartID && paymentIntentSuccess && <Redirect to="/order" />}
      {!cartID && !paymentIntentSuccess && <Redirect to="/" />}

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {(error || paymentIntentError) && (
                <Alert severity="error">
                  Произошла ошибка. Попроубйте чуть позже. Мы уже знаем об
                  ошибке и постараемся ее быстро исправить. {paymentIntentError}
                </Alert>
              )}
              {getStepContent(index, deliveryAreaData, setDeliveryData)}

              <div className={classes.actionsContainer}>
                <div className={classes.buttons}>
                  <Button
                    disabled={
                      activeStep === 0 || paymentIntentSubmitting || submitting
                    }
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Назад
                  </Button>
                  {activeStep === 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                      disabled={
                        !deliveryData.isValid ||
                        paymentIntentSubmitting ||
                        submitting
                      }
                    >
                      К оплате
                    </Button>
                  )}
                  {activeStep === 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Оплатить
                    </Button>
                  )}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>Ваш заказ принят!</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Ок
          </Button>
        </Paper>
      )}
    </div>
  );
};
export default Checkout;
