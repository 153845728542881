import { useEffect, useState } from "react";

const Subscriptions = "Subscriptions";

const useGetSubscriptions = (db) => {
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    if (db) {
      db.collection(Subscriptions).onSnapshot((querySnapshot) => {
        const dataList = [];
        querySnapshot.forEach(function (doc) {
          const docData = doc.data();
          docData.price = docData.price / 100;
          dataList.push({ id: doc.id, ...docData });
        });
        setSubscriptions(dataList);
        console.log("subscriptions", subscriptions);
      });
    }
  }, [db]);
  return { subscriptions };
};

export { useGetSubscriptions };

const useUpdateSubscription = (db) => {
  const [submitting, setSubmitting] = useState(false);
  const [successSubmitting, setSuccessSubmitting] = useState(false);
  const [errorSubmitting, setErrorSubmitting] = useState(null);

  const updateSubscription = async ({ subscriptionData }) => {
    setSubmitting(true);
    setSuccessSubmitting(false);
    setErrorSubmitting(false);
    subscriptionData.price = subscriptionData.price * 100;
    console.log("subscriptionData", subscriptionData);
    try {
      if (subscriptionData.id) {
        await db
          .doc(`${Subscriptions}/${subscriptionData.id}`)
          .update(subscriptionData);
      } else {
        await db.collection(Subscriptions).add(subscriptionData);
      }

      setSubmitting(false);
      setSuccessSubmitting(true);
      setErrorSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setSuccessSubmitting(false);
      setErrorSubmitting(error.message);
      console.log(error.message);
    }
  };
  return {
    updateSubscription,
    submitting,
    successSubmitting,
    errorSubmitting,
  };
};
export { useUpdateSubscription };
