import { useEffect, useState } from "react";

const useSignInWithEmailLink = (URL, email, removeEmail, firebase) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (firebase && !!email) {
      firebase
        .signInWithEmailLink(email, URL)
        .then(function (result) {
          removeEmail();

          setError(false);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error, VVV", error);
          setError(true);
          setLoading(false);
        });
    }
  }, [email, URL, firebase]);
  return { loading, error };
};
export { useSignInWithEmailLink };

const useSubmitEmail = (email, firebase, setLocalStorageEmail) => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (email.length > 3 && firebase) {
      setSubmitting(true);

      firebase
        .sendSignInLinkToEmail(email)
        .then(() => {
          console.log("Succesfully sent link");
          setSubmitting(false);
          setLocalStorageEmail(email);
          setSuccess(true);
          setError(undefined);
        })
        .catch((error) => {
          console.error("useSubmitEmail::error: ", error);
          setSubmitting(false);
          setSuccess(false);
          setError(error);
        });
    }
  }, [email]);
  return { submitting, success, error };
};

export { useSubmitEmail };
