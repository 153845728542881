import { useEffect, useState } from "react";

const Subscriptions = "Subscriptions";

const useGetSubscriptionPurchases = (db) => {
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    if (db) {
      db.collection("SubsriptionPurchases")
        .where("isActive", "==", true)
        .onSnapshot((querySnapshot) => {
          const dataList = [];
          querySnapshot.forEach(function (doc) {
            const docData = doc.data();
            docData.price = docData.price / 100;
            dataList.push({ id: doc.id, ...docData });
          });
          setSubscriptions(dataList);
          console.log("subscriptions", subscriptions);
        });
    }
  }, [db]);
  return { subscriptionPurchases: subscriptions };
};

export { useGetSubscriptionPurchases };

const useUpdateSubscription = (db) => {
  const [submitting, setSubmitting] = useState(false);
  const [successSubmitting, setSuccessSubmitting] = useState(false);
  const [errorSubmitting, setErrorSubmitting] = useState(null);

  const updateSubscription = async ({ subscriptionData }) => {
    setSubmitting(true);
    setSuccessSubmitting(false);
    setErrorSubmitting(false);
    subscriptionData.price = subscriptionData.price * 100;
    console.log("subscriptionData", subscriptionData);
    try {
      if (subscriptionData.id) {
        await db
          .doc(`${Subscriptions}/${subscriptionData.id}`)
          .update(subscriptionData);
      } else {
        await db.collection(Subscriptions).add(subscriptionData);
      }

      setSubmitting(false);
      setSuccessSubmitting(true);
      setErrorSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setSuccessSubmitting(false);
      setErrorSubmitting(error.message);
      console.log(error.message);
    }
  };
  return {
    updateSubscription,
    submitting,
    successSubmitting,
    errorSubmitting,
  };
};
export { useUpdateSubscription };

const useAddPurchase = (functions) => {
  const [submitting, setSubmitting] = useState(false);
  const [successSubmitting, setSuccessSubmitting] = useState(false);
  const [errorSubmitting, setErrorSubmitting] = useState(null);

  const addPurchase = async ({ email, subscription }) => {
    setSubmitting(true);
    setSuccessSubmitting(false);
    setErrorSubmitting(false);
    console.log("email, subscription ", email, subscription);
    try {
      //await db.collection("Admins").add({ email });
      var addPurchase = functions.httpsCallable("addPurchase");
      const result = await addPurchase({
        subscriptionID: subscription,
        clientEmail: email,
      });
      setSubmitting(false);
      setSuccessSubmitting(true);
      setErrorSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setSuccessSubmitting(false);
      setErrorSubmitting(error.message);
      console.log(error.message);
    }
  };

  return {
    addPurchase,
    submitting,
    successSubmitting,
    errorSubmitting,
  };
};

export { useAddPurchase };
