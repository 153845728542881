import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import Title from "../Title";

import { FirebaseContext } from "../../../Firebase";
import { useGetVerificationCodes, useExhaustVerificationCode } from "./hooks";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  actionButton: {
    position: "absolute",
    right: "20px",
    bottom: "10px",
  },
  imageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "center",
  },
  uploadButton: { margin: "8px" },
  imageInput: {
    display: "none",
  },
  itemImage: {
    maxWidth: "200px",
  },
}));

export default function ClientVerificationCodes() {
  const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);

  const handleUseSubscription = ({ codeID, subscriptionID }) => {
    console.log(codeID);
    invalidateVerificationCode({ codeID, subscriptionID });
  };

  const { codes } = useGetVerificationCodes(firebase.db);

  const {
    invalidateVerificationCode,
    submitting,
    successSubmitting,
    errorSubmitting,
  } = useExhaustVerificationCode(firebase);

  return (
    <React.Fragment>
      <Title>Коды проверок</Title>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Код</TableCell>
            <TableCell>Валидация</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {codes.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.code}</TableCell>
              <TableCell>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    handleUseSubscription({
                      codeID: row.id,
                      subscriptionID: row.subscriptionID,
                    })
                  }
                >
                  Принять
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
