export const statusLabelByStatus = (status) => {
  switch (status) {
    case "ORDER_PENDING":
    case "PENDING":
      return "Новый";
    case "ORDER_PREPARING":
    case "PREPARING":
      return "Готовится";
    case "ORDER_DELIVERING":
    case "DELIVERING":
      return "Доставка";
    case "ORDER_CANCELED":
    case "CANCELED":
      return "Отменен";
    case "ORDER_DONE":
    case "DONE":
      return "Выполнен";
    default:
      return "Статус неопределен";
  }
};
