import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import CartItem from "../CartItem";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    width: 151
  },
  priceBlock: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "16px"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  }
}));

const CartContent = ({ cartItems, cartAction }) => {
  const { updateCartWithItem, submitting, success, error } = cartAction;
  return (
    <>
      {cartItems.map(item => (
        <CartItem item={item} updateCartWithItem={updateCartWithItem} />
      ))}
      {cartItems.length > 0 && <CartTotal cartItems={cartItems} />}
    </>
  );
};
export default CartContent;

const CartTotal = ({ cartItems }) => {
  const classes = useStyles();
  return (
    <div className={classes.priceBlock}>
      <Typography variant="h4">
        Итого: ₽{" "}
        {Object.values(cartItems).reduce(
          (prev, cur) => prev + cur.totalPrice,
          0
        )}
      </Typography>
    </div>
  );
};
