import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";

const useStyles = makeStyles({
  card: {
    display: "flex",
    flexDirection: "column",
    minHeight: "450px"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: 190
  }
});

export default function FeaturedPost(props) {
  const classes = useStyles();
  const { menuItem, updateCartWithItem, updateStatus } = props;
  const { submitting, success, error } = updateStatus;

  React.useEffect(() => {
    console.log("success", success, "error", error, "submitting", submitting);
  }, [success, error, submitting]);

  return (
    <Grid item xs={12} md={3}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cardMedia}
          image={menuItem.url}
          title={menuItem.fullName}
          component="img"
        />

        <div className={classes.cardDetails}>
          <CardContent>
            <Typography component="h2" variant="h5">
              {menuItem.fullName}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {menuItem.price}₽
            </Typography>
          </CardContent>
        </div>
        <CardActions>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              console.log("CART CLICK", menuItem.id);
              updateCartWithItem(menuItem.id);
            }}
          >
            <ShoppingBasket />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object
};
