import { useEffect, useState } from "react";

/**
 *
 * {
 *  deliveryAreas:[{poly:[[],[],[]], minPrice:124500},{poly:[[],[],[],[],[],[]], minPrice:0}]
 * }
 */
const useGetShopSettingsData = db => {
  const [shopSettingsData, setShopSettingsData] = useState([]);

  useEffect(() => {
    if (db) {
      db.doc("settings/shopData").onSnapshot(docSnapshot => {
        setShopSettingsData({ id: docSnapshot.id, ...docSnapshot.data() });
      });
    }
  }, [db]);
  return { shopSettingsData };
};

export { useGetShopSettingsData };

const useUpdateShopSettingsData = db => {
  const [submitting, setSubmitting] = useState(false);
  const [successSubmitting, setSuccessSubmitting] = useState(false);
  const [errorSubmitting, setErrorSubmitting] = useState(null);

  const updateSettings = async settingsData => {
    setSubmitting(true);
    setSuccessSubmitting(false);
    setErrorSubmitting(false);
    console.log("settingsData", settingsData);
    try {
      // if (settingsData.id) {
      //   await db.doc(`settings/shopData`).update(settingsData);
      // } else {
      //   await db.collection("settings/shopData").add(settingsData);
      // }
      await db.doc(`settings/shopData`).update(settingsData);
      setSubmitting(false);
      setSuccessSubmitting(true);
      setErrorSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setSuccessSubmitting(false);
      setErrorSubmitting(error.message);
      console.log(error.message);
    }
  };
  return {
    updateSettings,
    submitting,
    successSubmitting,
    errorSubmitting
  };
};
export { useUpdateShopSettingsData };
