import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import UpdateIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import Title from "../Title";
import CategoryBar from "./components/CategoryBar";

import { FirebaseContext } from "../../../Firebase";
import {
  useGetMenuList,
  useAddMenuItem,
  useSendFiles,
  useGetCategories,
  useAddCategory
} from "./hooks";
import MenuItemForm from "./components/MenuItemForm";

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3)
  },
  actionButton: {
    position: "absolute",
    right: "20px",
    bottom: "10px"
  },
  imageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "center"
  },
  uploadButton: { margin: "8px" },
  imageInput: {
    display: "none"
  },
  itemImage: {
    maxWidth: "200px"
  }
}));

export default function Orders() {
  const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);
  const { menuList } = useGetMenuList(firebase.db);
  const { addMenuItem, submitting, success, error } = useAddMenuItem(
    firebase.db
  );
  const { isSending, sendFiles } = useSendFiles(firebase);

  const [open, setOpen] = React.useState(false);
  const [updateData, setUpdateData] = React.useState(null);
  const handleClickOpen = (data = null) => {
    setUpdateData(data);
    setOpen(true);
  };

  const handleClose = () => {
    console.log("closeeee");
    setUpdateData(null);
    setOpen(false);
  };

  const {
    addCategory,
    submittingCategory,
    successSubmittingCategory,
    errorSubmittingCategory
  } = useAddCategory(firebase.db);
  const { categoryList } = useGetCategories(firebase.db);
  const [activeCategory, setActiveCategory] = React.useState(null);

  const [visibleItems, setVisibleItems] = React.useState([]);
  React.useEffect(() => {
    if (
      !activeCategory ||
      activeCategory ===
        categoryList.find((element, index, array) => element.name === "Все").id
    ) {
      setVisibleItems(menuList);
    } else {
      setVisibleItems(
        menuList.filter(item => item.category === activeCategory)
      );
    }
  }, [activeCategory, categoryList, menuList]);

  return (
    <React.Fragment>
      <Title>Меню</Title>
      {categoryList.length > 0 && (
        <CategoryBar
          categoryList={categoryList}
          setActiveCategory={setActiveCategory}
          addCategory={addCategory}
          submittingCategory={submittingCategory}
          successSubmittingCategory={successSubmittingCategory}
          errorSubmittingCategory={errorSubmittingCategory}
        />
      )}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Название</TableCell>
            <TableCell>Цена</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleItems.map(row => (
            <TableRow key={row.id}>
              <TableCell>
                <IconButton
                  aria-label="delete"
                  className={classes.margin}
                  onClick={() => handleClickOpen(row)}
                >
                  <UpdateIcon fontSize="small" />
                </IconButton>
              </TableCell>
              <TableCell>{row.fullName}</TableCell>
              <TableCell>{row.price}₽</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.actionButton}
        onClick={() => handleClickOpen()}
      >
        <AddIcon />
      </Fab>
      <MenuItemForm
        handleClose={handleClose}
        open={open}
        submitEmployeeData={addMenuItem}
        submitting={submitting}
        success={success}
        error={error}
        updateData={updateData}
        menuList={menuList}
        sendFiles={sendFiles}
        isSending={isSending}
        categoryList={categoryList}
      />
    </React.Fragment>
  );
}
